import MessageMap from '../i18n';

const ptBR: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  ITEM_SERVICE_SECTION: {
    LOADING_MESSAGE: 'Carregando',
  },
  SIDEMENU: {
    HOME: 'Página inicial',
    LIST: 'Lista de vendedores',
    TOTAL_COVERAGE: 'Cobertura total',
  },
  HOME_PAGE: {
    TITLE: 'Gerenciamento de vendedores',
    LIST: {
      TITLE: 'Lista de vendedores',
      DESCRIPTION: 'Verificar todas as listas de vendedores',
    },
    TOTAL_COVERAGE: {
      TITLE: 'Cobertura total',
      DESCRIPTION:
        'Informações sobre áreas que os nossos pontos de venda estão atualmente cobertos',
    },
  },
  SELLER_LIST_PAGE: {
    TITLE: 'Lista de pontos de venda',
  },
  TOGGLE_MODAL: {
    TITLE: 'Alterar este vendedor para "{status}"?',
    TEXT: 'O status do vendedor <{seller}> será definido como "{status}"',
    TOAST: {
      SUCCESS: 'Vendedor alterado com sucesso para {status}',
      ERROR: 'Erro ao alterar o vendedor para {status}. Tente novamente.',
    },
    OPEN: 'aberto',
    CLOSE: 'fechado',
  },
  STATUS_MODAL: {
    TITLE: 'Altere o status para ativar',
    TEXT: 'Atribua um centro de entrega para tornar este vendedor ativo.',
    SELECT_PLACEHOLDER: 'Selecione um centro de entrega',
    CANCEL_TITLE: 'Você tem certeza que deseja cancelar?',
    CANCEL_TEXT: 'O status deste vendedor permanecerá o mesmo e não mudará para ativo.',
    BACK: 'Não, voltar',
    QUIT: 'Sim, sair',
  },
  TYPE_MODAL: {
    TITLE: 'Ativar ponto de venda',
    TEXT: 'Agora, atribua um tipo de ponto de venda a este ponto de venda. Esta ação é necessária para alterar seu status para “ativo”.',
    SELECT_PLACEHOLDER: 'Selecione um tipo de ponto de venda',
    ACTIVATE: 'Ativar',
  },
  BUTTONS: {
    GO_BACK: 'Voltar',
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    SAVE: 'Salvar',
    APPLY: 'Applicar',
    COPY: 'Copiar',
    EDIT: 'Editar',
    SELECT: 'Selecionar',
    SELECT_OR_EDIT: 'Selecionar ou Editar',
    CONTINUE: 'Continuar',
    SET_HOURS: 'Definir horário',
    ADD_HOURS: '+ Adicionar horários',
    NEXT: 'Próximo',
    SET_AREA_DEFAULT: 'Definir área',
    EDIT_AREA_DEFAULT: 'Editar área',
    SET_COVERAGE_AREA_SCHEDULED: 'Definir ou editar',
    ENABLE: 'Habilitar',
    DISABLE: 'Desabilitar',
  },
  SELLER_ATTRIBUTES: {
    DISPLAY_NAME: 'Nome fantasia',
    LEGAL_NAME: 'Razão social',
    CONTACTS: 'Contatos',
    PHONE: 'Telefone',
    EMAIL: 'E-mail',
    MAIL: 'E-mail',
    BILLING_ADDRESS: 'Endereço da fatura',
    DELIVERY_ADDRESS: 'Endereço de entrega',
    THOROUGHFARE: 'Endereço',
    COMPLEMENT: 'Complemento (apto, andar, etc)',
    NUMBER: 'Número',
    NEIGHBORHOOD: 'Bairro',
    REFERENCE: 'Referência',
    CITY: 'Cidade',
    STATE: 'Estado',
    ZIP_CODE: 'CEP',
    COORDINATES: 'Coordenadas',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    STATUS: 'Status',
    ONLINE: 'Online',
    SELLER_TYPE: 'Tipo de ponto de venda',
    TAX_ID: 'CNPJ',
    CREATED_AT: 'Data de criação',
    UPDATED_AT: 'Última atualização',
    FAX: 'Fax',
    SELLER_TYPES: {
      RESTAURANT: 'Restaurante',
      BAR: 'Bar',
      WINE_HOUSE: 'Casa de vinhos',
      CONVENIENCE_STORE: 'Loja de conveniência',
      MARKET: 'Mercado',
      GROCERY_STORE: 'Mercearia',
      BAKERY: 'Padaria',
      DISK_DELIVERY: 'Disk Delivery',
      PIT_STOP: 'Pit Stop',
      CHOPP_BRAHMA_EXPRESS: 'Chopp Brahma Express',
      DARK_STORE_3RD_PARTY: 'Dark store (3rd party)',
      OWNED_STORE: 'Owned Store',
      OTHERS: 'Others',
    },
  },
  DELIVERY_CENTER: {
    TITLE: 'Centro de distribuição',
    EMPTY:
      'Não há um centro de distribuição designado. Altere o status para "Ativo" para atribuir um.',
    ATTRIBUTES: {
      ID: 'ID',
      NAME: 'Nome',
    },
  },
  SELLER_TABLE: {
    PAGINATION: {
      QUANTITY_INDICATOR: 'de',
      PAGE_SIZE_OPTIONS: 'Itens por página: {options}',
    },
    EMPTY: {
      TITLE: 'Nenhum vendedor encontrado',
      MESSAGE: 'Esta busca não encontrou nenhum vendedor. Tente outra busca',
    },
    LOADING: {
      TITLE: 'Carregando',
      MESSAGE: 'Estamos preparando a lista de vendedores para você.',
    },
    FILTERED: {
      TITLE: 'Nenhum vendedor ainda',
      MESSAGE: 'Os vendedores serão exibidos aqui.',
    },
    ERROR: {
      400: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE: 'Ocorreu um erro inesperado. Tente novamente.',
      },
      401: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE:
          'Entre em contato com nossa equipe de suporte para revisar as configurações da sua conta.',
      },
      403: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE:
          'Entre em contato com nossa equipe de suporte para revisar as configurações da sua conta.',
      },
      404: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE: 'Não foi possível executar esta ação. Tente novamente.',
      },
      500: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE: 'Ocorreu um erro inesperado. Tente novamente.',
      },
      502: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE: 'Ocorreu um erro inesperado. Tente novamente.',
      },
      503: {
        TITLE: 'Ops, algo deu errado!',
        MESSAGE: 'Ocorreu um erro inesperado. Por favor, tente novamente.',
      },
    },
  },
  SELLER_DETAILS_PAGE: {
    STORE: 'Ponto de venda',
    OPEN: 'Aberto',
    CLOSED: 'Fechado',
    GENERAL_INFO: 'Informação geral',
    EDIT_TOAST: {
      SUCCESS: 'Informações do vendedor alteradas com sucesso.',
      ERROR: 'Erro nas informações atualizadas do vendedor. Tente novamente.',
    },
    STATUS_TOAST: {
      SUCCESS: 'Status do vendedor alterado com sucesso',
      ERROR: 'Erro ao alterar status do vendedor. Tente novamente.',
    },
    DELIVERY_CENTER_TOAST: {
      SUCCESS: 'Centro de entrega atribuído com sucesso',
      ERROR: 'Erro ao atribuir o centro de entrega. Tente novamente.',
    },
    BUSINESS_HOURS_MESSAGE: 'Para ativar este vendedor, defina o horário comercial.',
    EMAIL_HINT:
      'Este é o e-mail utilizado apenas para comunicações. Não é o e-mail de cadastro/login.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  VERSION: {
    VERSION_APP: 'Versão',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. Todos os direitos reservados.',
  },
  FILTERS: {
    PLACE_HOLDERS: {
      COUNTRY: 'Selecione um país',
      DELIVERY_CENTER: 'Todos os centros de entrega',
      STATUS: 'Selecione um status',
    },
    STATUS: {
      ALL: 'Todos os status',
      ACTIVE: 'Ativar',
      PENDING: 'Pendente',
      INACTIVE: 'Inativo',
      REJECTED: 'Recusar',
      BLOCKED: 'Bloqueado',
    },
  },
  FIELDS: {
    OPTIONAL: 'Opcional',
    REQUIRED: {
      GENERIC: 'Campo obrigatório',
      DISPLAY_NAME: 'Digite um nome de exibição',
      CITY: 'Digite o nome da cidade',
      STATE: 'Digite o nome do estado',
      ZIP_CODE: 'Digite o CEP',
      LATITUTE: 'Digite o número de latitude',
      LONGITUDE: 'Digite o número de longitude',
    },
    FORMAT: {
      GENERIC: 'Formato inválido',
      LATITUTE: 'O número de latitude deve estar entre -90 e 90',
      LONGITUDE: 'O número de longitude deve estar entre -180 e 180',
    },
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Ops, acesso negado!',
    DESCRIPTION: 'Parece que você não tem permissão para acessar esta página.',
    ACTION: 'Voltar para a página inicial',
  },
  NOT_FOUND_PAGE: {
    HEADER_TEXT: 'Mmm… Parece que esta página desapareceu',
    SUBHEADERTEXT: 'Parece que esta página não foi encontrada ou não existe.',
    BUTTON_TEXT: 'Voltar para a página inicial',
  },
  COVERAGE_AREA: {
    TITLE: 'Área de cobertura',
    SELECTOREDIT: 'Selecione ou edite a área de cobertura',
    HELP_TEXT:
      'Para a localização do vendedor, consideramos as coordenadas informadas no endereço de entrega',
    MAP_CAPTION: {
      TITLE: 'Legenda do mapa',
      STORE: 'Ponto de venda',
    },
    CREATE_AREA: {
      TITLE: 'Criar uma área de cobertura',
      DESCRIPTION: 'Crie uma área de cobertura desenhando um círculo e movendo-o para editar.',
      DESCRIPTION_MULTI_POLYGON:
        'Create a coverage area by drawing a circle and adjusting the edit points, or select a new active coverage area',
      NEW_AREA_ACTION: 'Criar nova área',
      CREATE_OR_SELECT_AN_AREA: 'Create or Select an area',
      SELECT_AN_ACTIVE_COVERAGE_AREA: 'Select an active coverage area',
    },
    EDIT_AREA: {
      TITLE: 'Edit area',
      DESCRIPTION: 'Change the coverage area by drawing a circle and adjusting the edit points',
      NAME_INPUT: 'Nome da área',
      NAME_INPUT_PLACEHOLDER: 'Ex: horário noturno',
      NAMEPLACEHOLDER: 'Regular hours',
      NAME_ERROR:
        'You already have a coverage area with the same name. Enter a different one to continue.',
      EDIT_SELECTED_COVERAGE_AREA: 'Edit selected coverage area',
      EDITING_ACTIONS: {
        TITLE: 'EDIT THE AREA USING:',
        RADIUS: 'Raio (Km)',
        EDIT_POINTS: 'Editar pontos',
        EDIT_POINTS_DESCRIPTION:
          'O raio será redefinido na próxima vez que você selecionar essa opção.',
        RADIUS_DESCRIPTION: 'Raio recomendado: 9km',
        DELETE_AREA: 'Delete area',
      },
      DELETE_COVERAGE_AREA_WITH_AREA_NAME: 'Do you want to delete <{area}>?',
      DELETE_COVERAGE_AREA: 'Delete coverage area',
      SELECT_COVERAGE_AREA:
        'Do you want <{area}> to become the active coverage area for this seller?',
      CHANGE_ACTIVE_COVERAGE_AREA: 'Change active coverage area',
    },
    EDIT_AREA_DIALOG: {
      TITLE: 'Edit coverage area',
      TITLE_SELECT_OR_EDIT: 'Select or edit coverage area',
      SUBTITLE: 'You must edit the coverage area so the seller can be activated.',
    },
    TOAST: {
      SUCCESS: 'Active coverage area successfully changed.',
      ERROR: 'We couldn’t change the active coverage area. Try again',
    },
  },
  STORE_STATUS: {
    ACTIVE: 'Ativado',
    PENDING: 'Pendente',
    INACTIVE: 'Inativo',
    REJECTED: 'Rejeitado',
    BLOCKED: 'Bloqueado',
  },
  DEFAULT_COVERAGE_AREA: {
    TITLE: 'Área de cobertura',
    HELP_TEXT:
      'This is the main delivery area. It will be active when there are no active schedules.',
    HELP_TEXT_MODAL:
      'Área de entrega principal. Enquanto não houver programações ativas, a área padrão permanecerá ativa',
    FORM: {
      TITLE_CREATE: 'Criar uma área de cobertura',
      TITLE_EDIT: 'Editar uma área',
      DESCRIPTION: 'Crie uma área de cobertura desenhando um círculo e movendo-o para editar.',
      PLACE_HOLDER_NAME: 'Área padrão',
      LABEL_NAME: 'Nome da área',
      TYPE_COORDINATES_LABEL: 'Editar a área usando',
      BUTTON_SAVE: 'Salvar',
      BUTTTON_CANCEL: 'Voltar',
    },
    TOAST: {
      SUCCESS_CREATE: 'Área de cobertura padrão criada com sucesso',
      SUCCESS_EDIT: 'Área de cobertura padrão editada com sucesso',
      ERROR: 'It was not possible to complete the action. Try again',
      ERROR_INTERSECTION:
        'Área de intersecção! Por favor, defina uma área em que os pontos não se cruzem.',
    },
    CONFIRM_DEFAULT_COVERAGE_AREA_DIALOG: {
      TITLE: 'Create default area',
      SUB_TITLE: 'You must to set the default area so the store can be activated.',
      BUTTON_CONFIRM: 'Create area',
      BUTTON_BACK: 'Voltar',
    },
  },
  SCHEDULED_COVERAGE_AREA: {
    TITLE: 'Área de cobertura programada',
    TITLE_MODAL: 'Área de cobertura programada',
    HELPTEXT:
      'Temporary coverage area with defined days and times. On all other days and times, the store operates with the standard delivery area.',
    HELP_TEXT_MODAL:
      'Defina uma área de entrega temporária para dias e horários específicos. Quando inativas, as entregas revertem para a área de cobertura padrão.',
    EMPTY_CONTENT: 'Este ponto de venda ainda não tem áreas programadas.',
    FILLED_CONTENT: 'Este ponto de venda ainda não tem áreas programadas.',
    FORM: {
      TITLE_CREATE: 'Criar uma área de cobertura',
      TITLE_EDIT: 'Editar uma área',
      DESCRIPTION: 'Crie uma área de cobertura desenhando um círculo e movendo-o para editar.',
      LABEL_NAME: 'Nome da área (obrigatório)',
      PLACE_HOLDER_NAME: 'Ex: horário noturno',
      HELPER_TEXT_NAME: 'O nome da área não poderá ser alterado posteriormente.',
      TYPE_COORDINATES_LABEL: 'Editar a área usando',
      BUTTON_SAVE: 'Salvar',
      BUTTTON_CANCEL: 'Voltar',
      BUTTON_REMOVE: 'Delete area',
      SCHEDULING_LABEL: 'Programação (obrigatória)',
      SCHEDULING_TEXT: 'Gerenciar programação',
      ERRORS: {
        NAME: 'Esse nome já foi usado em outra área.',
      },
    },
    SCHEDULING: {
      TITLE: 'Programação',
      SUB_TITLE: 'Ajustes',
      INFO_TEXT: 'Escolha o dia e o horário de início e término para agendar a área.',
      ADD_TEXT: '+ Adicionar novo dia e horário',
      DAY_LABEL: 'Dia da semana',
      START_AT_LABEL: 'Horário de início',
      END_AT_LABEL: 'Horário de término',
      ERRORS: {
        ALREADYSELECTED: 'Este período de tempo já foi selecionado em outra área.',
      },
    },
    TOAST: {
      SUCCESS_CREATE: 'Área de cobertura criada com sucesso',
      SUCCESS_EDIT: 'Área de cobertura editada com sucesso',
      SUCCESS_REMOVE: 'Área de cobertura excluída com sucesso',
      ERROR: 'Não foi possível completar a ação. Tente novamente',
      ERROR_INTERSECTION:
        'Área de intersecção! Por favor, defina uma área em que os pontos não se cruzem.',
    },
    MANAGE_AREA: {
      TITLE: 'Criar uma área de cobertura',
      DESCRIPTION: 'Crie uma área de cobertura desenhando um círculo e movendo-o para editar.',
      BUTTON_CREATE_NEW_AREA: 'Criar nova área',
      EDIT_AREA_LABEL: 'Selecionar uma área para editar',
      DAYS_AND_HOURS_SELECTED_AREA_CARD: 'Edit selected coverage area',
      DAYSANDHOURSSELECTEDAREACARD: {
        TITLE: 'Days and times when the area will be activated',
        TEXT_BETWEEN_START_AT_AND_FINISH_AT: 'to',
      },
    },
    TOGGLE: {
      TEXT: 'Este ponto de venda ainda não tem áreas programadas.',
      ENABLE: {
        TITLE: 'Are you sure you want to enable the scheduled area?',
        FIRST_TEXT: 'By enabling this functionality, the area will be indicated with the tag',
        SECOND_TEXT: 'when the scheduled time arrives.',
      },
      DISABLE: {
        TITLE: 'Are you sure you want to disable the scheduled area?',
        TEXT: "By disabling this functionality, scheduled areas won't  be automatically activated when the scheduled time starts.",
      },
    },
    TIMEZONE_INFO: {
      NAME_TITLE: 'Este ponto de venda pertence a',
      NAME_VALUE: 'Fuso horário:',
      CURRENT_TIME: 'Horário atual',
    },
  },
  WEEK_DAYS: {
    SUNDAY: 'Domingo',
    MONDAY: 'Segunda',
    TUESDAY: 'Terça',
    WEDNESDAY: 'Quarta',
    THURSDAY: 'Quinta',
    FRIDAY: 'Sexta',
    SATURDAY: 'Sábado',
  },
  BUSINESS_HOURS: {
    TITLE: 'Horário comercial',
    EDIT: 'Definir horário comercial',
    EMPTY:
      'O horário comercial não foi definido. Essa ação é necessária para ativar esse ponto de venda.',
    OPENS_AT: 'Abre às',
    CLOSES_AT: 'Fecha às',
    TOAST: {
      SUCCESS: 'Horário comercial atualizado.',
      ERROR: 'Unable to update business hours. Try again.',
    },
  },
  TOTAL_COVERAGE_PAGE: {
    TITLE: 'Total coverage',
    FILTERS: {
      FILTERED_BY: 'Filtered by',
      COUNTRY: 'Country',
      FILTER_BUTTON: 'Filter',
      SEARCH_PLACE_HOLDER: 'Search address',
      SEARCH_BUTTON: 'Search',
      BUTTON_APPLY_FILTER: 'Apply',
      BUTTON_CANCEL: 'Cancel',
      OPERATION_STATUS: 'Current Status',
      DELIVERY_CENTERS: 'Delivery Centers',
      ALL_DELIVERY_CENTERS: 'All delivery centers',
    },
    UPDATE_MAP_BUTTON: 'Update map',
    MAP_LOADING: {
      TITLE: 'Loading',
      TEXT: 'We are preparing the map for you.',
    },
    OPERATION_STATUS: {
      CLOSED: 'Closed',
      OPEN: 'Open',
      SHOULD_BE_OPEN: 'Should be open',
    },
    LAST_UPDATED: 'Last updated:',
    TOAST_ERROR_LOADING: 'Unable to load your request. Please try again',
    SELLER_DETAILS: {
      PHONE: 'Phone',
      BUTTON_GO_TO_STORE: 'Go to store',
      BUSINESS_HOURS: 'Business hours',
      BUSINESS_HOURS_NOT_PROVIDED: 'Business hours not provided',
    },
    SELLER_LIST: {
      NUMBER_SELLERS: 'Number of points of sale in this area:',
      SHOW_MORE: '(go to the store)',
    },
  },
};

export default ptBR;
